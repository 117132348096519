import qs from 'qs';

export const setTmAttrs = () => {
  const {search} = window.location;
  if (search) {
    const query = qs.parse(search.slice(1));
    Object.keys(query).forEach((key) => {
      if (key.startsWith('tm_')) {
        sessionStorage.setItem(key, query[key]);
      }
    });
  }
};

export const getTmAttrs = () =>
  Object.keys(sessionStorage).reduce((acc, key) => {
    if (key.startsWith('tm_')) {
      acc[key] = sessionStorage[key];
    }
    return acc;
  }, {});
