import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import spinnerEclipseSVG from 'assets/images/svg/spinner/eclipse.svg';

const Loader = ({img, className, message}) => {
  const classes = classNames('block text-center', className);

  return (
    <div className={classes}>
      <img src={img} role="presentation"/>
      {message && <p className="text-center mt-3">{message}</p>}
    </div>
  );
};

Loader.propTypes = {
  img: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]).isRequired,
  className: PropTypes.string,
  message: PropTypes.string
};

Loader.defaultProps = {
  img: spinnerEclipseSVG,
  message: ''
};

export default Loader;
