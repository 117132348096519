import React, {lazy, Suspense} from 'react';
import {render} from 'react-dom';
import Loader from 'helpers/Loader';
import {setTmAttrs} from 'ferries/helpers/tapfiliate';
import spinnerCometSVG from 'assets/images/svg/spinner/comet.svg';

const Homepage = lazy(() => import('ferries/Home/Page'));
setTmAttrs();

render((
  <Suspense fallback={<Loader img={spinnerCometSVG} className='m-3' />}>
    <Homepage />
  </Suspense>), document.getElementById('root')
);
